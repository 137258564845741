<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="WhatsApp" :subtitle="'Templates'" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="col-lg-6 template-filters">
            <div class="filter-search">
              <form @submit.prevent="search">
                <div class="input-group">
                  <input
                    :placeholder="$t('generic-str.example')"
                    type="text"
                    class="form-control"
                    :v-model="searchQuery"
                    ref="searchInput"
                  />
                  <div class="input-group-prepend">
                    <button type="button" class="btn icon_btn" @click="search">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="input-group sort-group">
              <div class="input-group-prepend">
                <select class="form-control" style="width: 120px;" v-model="form.sort">
                  <option selected value='id'>ID</option>
                  <option value="status">STATUS</option>
                  <option value="name">{{$tc('generic-str.name', 1)}}</option>
                </select>
                <button class="btn btn-yup-purple" @click="fetchTemplates(form.page)">
                  {{$t('generic-str.sort')}}
                </button>
              </div>
            </div>
            <div class="input-group create-group">
              <div class="input-group-prepend">
                <router-link
                  :to="'/whatsapp/templates/new'"
                  class="btn btn-yup-purple-outline"
                  style="display: flex"
                >
                  <span class="material-symbols-outlined"> add </span
                  ><span>{{ $t('sms.templates.lbl-create') }}</span>
                  <!-- Criar novo template -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!templates.length && fetched" class="col-lg-12">
          <LottieAnimNoData />
          <h5 class="card-title text-center m-t-20">
            {{ $t('sms.templates.none') }}
          </h5>
          <!-- Nenhum template cadastrado -->
          <p class="text-center">{{ $t('sms.templates.lbl-register') }}</p>
          <!-- Cadastre seu primeiro template. -->
        </div>
        <!-- Templates Column -->
        <div class="col-lg-6" v-if="fetched && templates.length">
          <div class="col-lg-12 d-flex align-items-center">
            <div class="control-group opt-2">
              <label
                class="control control-checkbox select-all-checkbox"
                for="customCheckTAll"
              >
                <input
                  v-model="selectAllTemplates"
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheckTAll"
                />
                <div class="control_indicator"></div>
                <span>{{ $t('generic-str.all') }}</span>
              </label>
            </div>
            <button
              class="btn btn-yup-purple-outline btn-delete"
              :class="{
                'qt-loader qt-loader-mini qt-loader-right': isSending,
              }"
              @click="deleteAll"
              v-bind:disabled="selectedTemplates.length == 0 || isSending">
              <span class="material-symbols-outlined">delete</span><!-- Excluir -->
            </button>
          </div>
          <div
            class="card card-templates"
            v-for="(list, i) in templates"
            :key="list.id"
          >
            <div class="card-header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 col-sm-10 template-head-infos">
                    <div class="control-group opt-2">
                      <label
                        class="control control-checkbox"
                        :for="'template-check' + i"
                      >
                        <input
                          v-model="selectedTemplates"
                          type="checkbox"
                          class="custom-control-input"
                          :id="'template-check' + i"
                          :value="list"
                        />
                        <div class="control_indicator"></div>
                      </label>
                    </div>
                    <span class="mr-2 text-content"
                      >{{ $t('send-component.title') }}:
                      <span>{{ list.name }}</span></span
                    >
                    <span v-if="list.status == 'submitted'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                    <span v-if="list.status == 'created'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                    <span v-else-if="list.status == 'accepted'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                    <span v-else-if="list.status == 'failed'" class="icon_status"><span class="material-icons icon-failed">verified</span></span>
                    <span v-else-if="list.status == 'rejected'" class="icon_status"><span class="material-icons icon-failed">verified</span></span>
                    <span v-else-if="list.status == 'pending'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                    <span v-else-if="list.status == 'active'" class="icon_status"><span class="material-icons icon-success">verified</span></span>
                    <span v-else-if="list.status == 'approved'" class="icon_status"><span class="material-icons icon-success">verified</span></span>
                    <!-- <span class="max-200"
                      >ID: <span>{{ list.namespace }}</span></span
                    > -->
                  </div>
                  <div class="col template-opt">
                    <span :id="'visibility-n-' + i" class="material-icons visibility-icon" @click="showPreview(i, list)">visibility</span>
                    <dropdown>
                      <template v-slot:text>
                        <span class="material-symbols-outlined title" >
                          more_vert
                        </span>
                      </template>
                      <template #items>
                        <button
                          @click="callPreview('preview-modal', list)"
                          class="dropdown-item"
                        >
                          Preview
                        </button>
                        <button
                          class="dropdown-item"
                          @click="deleteTemplate(list.name)"
                        >
                          {{ $t('generic-str.remove') }}
                        </button>
                      </template>
                    </dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <p class="card-text" v-if="list.components[2]">{{ list.components[2].text }}</p>
              <p class="card-text" v-else-if="list.components[1]">{{ list.components[1].text }}</p>
              <p class="card-text" v-else-if="list.components[0]">{{ list.components[0].text }}</p>
              <p class="card-text" v-else>Nada encontrado!</p>
            </div>
            <div class="card-footer">
              <small><!-- {{ template.created_at }} --></small>
            </div>
          </div>
        </div>
        <!--/Templates Column -->
        <!-- <div v-else class="qt-block-ui relative" style="padding: 120px" /> -->
        <div v-if="!fetched" class="loading min-h-300">
          <LoadingAnim />
        </div>
        <!-- Preview Column -->
        <div v-if="fetched && templates.length" class="col-lg preview-div">
          <div class="preview-wrapper">
            <div class="card card-preview">
              <div v-if="!this.fetchPreview" class="card-body">
                <div class="lazy-wrapper mb-4">
                  <template v-for="(item, index) in items">
                    <span :key="index.value" class="mb-0 animated-background" :class="`w-${item.value}`"></span>
                  </template>
                </div>
                <div class="lazy-wrapper">
                  <template v-for="(item, index) in items">
                    <span :key="index.value" class="mb-0 animated-background" :class="`w-${item.value}`"></span>
                  </template>
                </div>
              </div>
              <div v-else class="card-body">
                <div class="img-wrapper" v-if="this.waba_img">
                  <img :src="this.waba_img" alt="">
                </div>
                <p class="card-text" v-if="this.waba_body">
                  {{this.waba_body}}
                </p>
                <p v-else class="card-text">
                  Olá cliente 🤩 Voce ganhou um Cupom de desconto valido ate o
                  dia xxx.
                </p>
                <p class="card-text footer-item" v-if="this.waba_footer">
                  {{this.waba_footer}}
                </p>
                <p v-else class="card-text footer-item">
                  Clique em um dos '<span class="material-icons visibility-icon">visibility</span>' para pré-visualizar o seu template 😀
                </p>
                <div class="preview-message-btns">
                  <span type="button" class="btn-link-preview" v-if="this.waba_btn_type == 'PHONE_NUMBER'"><i class="fas fa-phone"></i> {{this.waba_btn_text}}</span>
                  <span type="button" class="btn-link-preview" v-if="this.waba_btn_type == 'URL'"><i class="fas fa-link"></i> {{this.waba_btn_text}}</span>
                  <span type="button" class="btn-link-preview" v-if="this.waba_btn_type == 'QUICK_REPLY'">{{this.waba_btn_text}}</span>
                </div>
              </div>
            </div>
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview Column -->
        <br />
        <pagination :lastPage="pages" @change="fetchTemplates" />
        <!-- <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12">
                  <div class="input-group">
                    <div class="">
                      <router-link
                        :to="'/whatsapp/templates/new'"
                        class="btn btn-success"
                        v-if="client.active"
                      >
                        <i class="icon dripicons-plus"></i>{{$t('sms.templates.lbl-create')}}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9">
                  <div class="input-group" style="flex-direction: row-reverse;">
                    <div class="input-group-prepend">
                      <button class="btn btn-danger" @click="fetchTemplates(form.page)">
                        {{$t('generic-str.sort')}}
                      </button>
                      <select class="form-control" style="width: 120px;" v-model="form.sort">
                        <option selected value='id'>ID</option>
                        <option value="status">STATUS</option>
                        <option value="name">{{$tc('generic-str.name', 1)}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!templates.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i
                    class="fas fa-envelope font-size-80"
                    v-tooltip.top="
                      $t('template-component.tooltip')
                    "
                  ></i>
                  <h5 class="card-title m-t-20">{{$t('sms.templates.none')}}</h5>
                  <p>{{$t('sms.templates.lbl-register')}}</p>
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="templates.length" class="bg-light">
                    <tr>
                      <th scope="col" class="text-left" style="width: 90%">{{$tc('generic-str.name', 1)}}</th>
                      <th scope="col" class="text-left">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(list, i) in templates"
                      :key="list.id"
                    >
                      <td data-label="Nome: " class="text-left m-text-right" style="padding-left: 20px;">
                        {{ list.name }}
                      </td>
                      <td
                        data-label="Status: "
                        class="text-left m-text-right"
                      >
                        <span v-if="list.status == 'submitted'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span v-if="list.status == 'created'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span v-else-if="list.status == 'accepted'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span v-else-if="list.status == 'failed'" class="badge badge-danger">{{$t('generic-str.status.lbl-failure')}}</span>
                        <span v-else-if="list.status == 'rejected'" class="badge badge-danger">{{$t('generic-str.status.lbl-rejected')}}</span>
                        <span v-else-if="list.status == 'pending'" class="badge badge-warning">{{$tc('generic-str.status.lbl-pending', 1)}}</span>
                        <span
                          v-else-if="list.status == 'active'"
                          class="badge badge-success"
                        >{{$t('generic-str.status.lbl-active')}}</span>
                        <span
                          v-else-if="list.status == 'approved'"
                          class="badge badge-success"
                        >{{$t('generic-str.status.lbl-active')}}</span>
                      </td>
                      <td data-label="" class="text-right">
                        <div class="dropdown" style="margin-right: 10px">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style=""
                          >
                            <a
                              class="dropdown-item"
                              @click="deleteTemplate(list.name)"
                              >{{$t('generic-str.remove')}}</a>
                            <a class="dropdown-item" @click="callPreview('preview-modal', list)">Preview</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetchTemplates" />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>
    <create-template-modal id="create-template-modal"/>
    <send-template-modal id="send-template-modal"/>
    <preview-modal id="preview-modal"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import WhatsAppService from '@/services/whatsapp.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import PreviewModal from '@/components/whatsapp/PreviewModal.vue';
import Dropdown from '@/components/Dropdown.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    Pagination,
    CreateTemplateModal,
    PreviewModal,
    SendTemplateModal,
    Dropdown,
    LoadingAnim,
    LottieAnimNoData,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/reports/history',
        2: '/whatsapp/templates',
      },

      show: false,
      searchQuery: null,
      isSending: false,
      fetched: false,
      fetchPreview: Boolean,
      templates: [],
      client: {},
      form: {
        page: 1,
        limit: 400,
        sort: 'id',
      },
      pages: 1,
      selectedTemplates: [],
      waba_body: '',
      waba_footer: '',
      waba_img: '',
      waba_btn_type: '',
      waba_btn_url: '',
      waba_btn_text: '',
      shuffledNumbers: null,
      items: [
        { value: '50' },
        { value: '75' },
        { value: '50' },
        { value: '75' },
        { value: '50' },
        { value: '75' },
        { value: '75' },
        { value: '50' },
        { value: '75' },
        { value: '50' },
      ],
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.templates) {
          return this.selectedTemplates.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedTemplates = selected;
      },
    },
  },
  created() {
    this.fetch(1);
    this.$root.$on('template.submit', this.fetch);
    // this.randomize();
  },
  methods: {
    callModal(id) {
      const data = {
        language: 'pt_BR',
        client_id: this.client.id,
        body_examples: [],
        buttons: [],
        category: 'Account Update',
        header: { type: 'TEXT', text: '' },
      };
      this.$root.$emit('show.modal', id, data);
    },
    callPreview(id, data) {
      console.log('TESTE DATA: ', data);
      this.$root.$emit('show.modal', id, data);
      this.$root.$emit('updated.previewwhats', data);
    },
    fetch() {
      Promise.all([
        WhatsAppService.getClient(),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.client = responses[0].data;
            this.form.client_id = this.client.id;
            this.fetchTemplates(1);
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          // this.fetched = true;
        });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('template-component.remove'),
        text: this.$t('template-component.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedTemplates.forEach((item) => {
            toDelete.push(item.name);
          });
          // console.info('Array: ', toDelete);
          this.isSending = true;
          WhatsAppService.deleteTemplates({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.deleted'),
                type: 'success',
              });
              this.fetch(1);
              this.isSending = false;
              this.selectedTemplates = [];
            },
            (error) => {
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    deleteTemplate(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('template-component.warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetchedItens = false;
          // console.info('ID to delete: ', id);
          Promise.all([
            WhatsAppService.deleteTemplate(id, this.form),
          ])
            .then(
              (responses) => {
                console.log(responses);
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
              },
            )
            .finally(() => {
              this.fetchedItens = true;
            });
        }
      });
    },
    search() {
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      WhatsAppService.getTemplates(this.form).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data.data.waba_templates;
          // console.log('Templates: ', this.templates);
          result = this.templates.filter((item) => word.toLowerCase().split(' ').every((v) => item.name.toLowerCase().includes(v)));
          // console.log('Return result: ', result);
          this.templates = result;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    showPreview(previewIndex, data) {
      this.fetchPreview = false;
      const previewValue = previewIndex;
      // const img = document.getElementById(`img-${previewValue}`);

      // this.shuffle();

      console.log('Preview: ', data);

      const elems = document.querySelectorAll('.visibility-icon');
      [].forEach.call(elems, (el) => {
        el.classList.remove('active');
      });
      const visibilityIcon = document.getElementById(`visibility-n-${previewIndex}`);
      visibilityIcon.classList.add('active');

      this.fetchPreview = true;
      this.waba_img = '';
      data.components.forEach((component) => {
        if (component.type === 'HEADER' && component.format === 'IMAGE') {
          this.waba_img = component.example.header_handle[0];
        }
        if (component.type === 'BODY') {
          this.waba_body = component.text;
        }
        if (component.type === 'FOOTER') {
          this.waba_footer = component.text;
        }
      });
      if (data.components[0]) {
        if (data.components[0].buttons) {
          if (data.components[0].buttons.type === 'URL') {
            this.waba_btn_type = data.components[0].buttons[0].type;
            this.waba_btn_url = data.components[0].buttons[0].url;
            this.waba_btn_text = data.components[0].buttons[0].text;
          } else {
            // Reset
            this.waba_btn_url = '';

            this.waba_btn_type = data.components[0].buttons[0].type;
            this.waba_btn_text = data.components[0].buttons[0].text;
          }
        }
      }
      /* WhatsAppService.getTemplates(this.form).then(
        (response) => {
          this.fetchPreview = true;

          const templateResponse = response.data.data.waba_templates[previewValue];
          // this.templates = response.data.data.waba_templates;
          console.log('Preview body: ', templateResponse.components[2].text);
          if (templateResponse.components[2].text.length) {
            this.waba_body = templateResponse.components[2].text;
          }
          if (templateResponse.components[1].text.length) {
            this.waba_footer = templateResponse.components[1].text;
          }
        },
        (error) => {
          this.content = error;
        },
      ); */
    },
    /* shuffle() {
      const numbers = [...this.items];
      let first;
      let second;
      let temp;
      const count = numbers.length;
      for (let i = 0; i < 10; i += i) {
        first = Math.floor(Math.random() * count);
        second = Math.floor(Math.random() * count);
        temp = numbers[first];
        numbers[first] = numbers[second];
        numbers[second] = temp;
      }
      this.shuffledNumbers = numbers;
    }, */
    fetchTemplates(page) {
      this.form.page = page;
      console.log(page);
      this.fetchedItens = false;
      this.fetched = false;
      Promise.all([
        WhatsAppService.getTemplates(this.form),
      ])
        .then(
          (responses) => {
            console.log('Templates');
            this.fetched = true;
            console.log(responses[0]);
            if (responses[0].data.data) {
              this.templates = responses[0].data.data.waba_templates;
              this.pages = Math.trunc(responses[0].data.data.total / 10);
            }
            this.fetchedItens = true;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetchedItens = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}

/* Rebranding */
.select-all-checkbox {
  width: fit-content;
  display: flex;
  gap: 10px;
  margin: 1rem;
}

.template-opt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.template-opt ::v-deep .dropdown a {
  display: flex;
}

.template-head-infos {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-head-infos span > span {
  font-weight: 400;
}

.card-templates .card-text {
  width: fit-content;
  background-color: #f2ecfd;
  padding: 4px 10px;
}

.darkmode .card-templates .card-text {
  background-color: var(--background-3);
}

.card-templates .card-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.card-templates .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-templates .card-footer small {
  margin-left: auto;
  color: var(--gray-font-color);
}

.filter-search .btn {
  padding: 0;
}

.filter-search ~ .input-group {
  justify-content: flex-end;
}

.icon_btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: var(--form-input);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 15px !important;

  span {
    color: var(--gray-font-color);
  }
}

.darkmode .icon_btn {
  background-color: var(--form-input);
}

.template-filters {
  display: flex;
  gap: 15px;
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}

/* Phone preview */
.preview-div {
  display: flex;
  // position: relative;
  /* align-items: center; */
  position: sticky;
  top: 100px;
  height: fit-content;
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.visibility-icon {
  color: var(--gray-font-color);
  cursor: pointer;
  transition: .5s;
}

.visibility-icon.active {
  color: var(--clr-yup-purple) !important;
}

.card-preview {
  .footer-item {
    font-size: 1.15rem;

    span.material-icons {
      transform: translateY(5px);
    }
  }
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }
  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }
  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
  }
  .card-preview {
    position: absolute;
    width: 410px;
    // top: 200px;
    right: 20%;
    // margin-top: 4rem;
    margin-bottom: 0 !important;
  }
  .card-preview .card-body {
    padding: 1rem 1rem 2rem;

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
  .filter-search {
    max-width: 400px;
    width: inherit;

    input {
      border-right-color: var(--form-input) !important;
    }
  }
}

@media (max-width: 767.98px) {
  .template-filters {
    flex-wrap: wrap;
  }
  .filter-search {
    width: 100%;
  }
  .sort-group {
    .input-group-prepend {
      .form-control {
        width: fit-content !important;
      }
    }
  }
  .icon_status {
    position: absolute;
    bottom: 0;
    right: 10px;
  }
  .card-templates {
    position: relative;
  }
  .template-head-infos, .card-templates .card-header {
    position: static;
  }
  .template-head-infos {
    padding-right: 0;
    padding-left: 0;
    margin-top: 10px;
  }
  .template-opt {
    position: absolute;
    padding: 0;
    top: 5px;
    right: 0;
  }
  .card-templates .card-text {
    margin-bottom: 1rem;
  }
  .template-opt {
    margin-left: 5px !important;
  }
  .text-content {
    word-break: break-word;
  }
}

.sort-group, .create-group {
  width: auto;
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}
.preview-message-btns i {
  color: #00a5f4;
}
.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}

.btn-delete {
  padding: 5px;
}
</style>
